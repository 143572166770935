import React from 'react';
import {Col, Container, Row} from "react-bootstrap";
import HtmlParser from 'react-html-parser';
import {checkData} from "../../helper";

function Developmenttechstack({data}) {
    if (data && data.development_tech_stack_title) {
        return (
            <section className="developmenttechstack p-100">
                <Container>
                    <Row>
                        <Col xs={12} md={12} lg={12} xl={12} xxl={12} className="my-auto">
                            <h2>{checkData(data, 'development_tech_stack_title')}</h2>
                            <div
                                className="solutionled_desc">{HtmlParser(checkData(data, 'development_tech_stack_description'))}</div>
                        </Col>
                    </Row>
                </Container>
            </section>
        );
    } else {
        return ('');

    }
}

export default Developmenttechstack;

